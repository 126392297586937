import {
    SET_GA_AUTHORIZED,
    SET_GA_PROFILES_DATA,
    SET_GA_PROFILES,
    SET_GA_USERNAME,
} from '../mutations';
import apiFactory from '@/api';
const gaApi = apiFactory.get('ga');
const googleAnalytics = {
    state: {
        gaAuthorized: null,
        gaProfiles: [],
        gaProfilesData: [],
        gaUsername: null,
    },
    mutations: {
        [SET_GA_AUTHORIZED]: (state, payload) => state.gaAuthorized = payload,
        [SET_GA_PROFILES_DATA]: (state, payload) => state.gaProfilesData = payload,
        [SET_GA_PROFILES]: (state, payload) => state.gaProfiles = payload,
        [SET_GA_USERNAME]: (state, payload) => state.gaUsername = payload,
    },
    getters: {
        getGAAuthorized: state => state.gaAuthorized,
        getGAProfiles: state => state.gaProfiles,
        getGAProfilesData: state => state.gaProfilesData,
        getGAUsername: state => state.gaUsername,
    },
    actions: {
        /**
         * The action that fetches linked GA account profiles by api
         * and put it into the store in case of successful response.
         * Also it calls fetch profiles data action
         */
        async fetchGAAccountProfiles ({ commit, dispatch, getters }) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(i18n.t('upgrade-for-google', {service: i18n.t('google-analytics')}));
                return false;
            }

            const res = await gaApi.fetchGaAccountProfiles();

            if (res.data.ga_authorized) {
                commit(SET_GA_USERNAME, res.data.ga_username);
                commit(SET_GA_AUTHORIZED, res.data.ga_authorized);
                commit(SET_GA_PROFILES, res.data.data.map(el => {
                    let id = el.split(':')[1].split('|')[0];
                    let domain = el.split('|')[1];
                    return {
                        label: `GA: ${domain} - ${id}`,
                        value: el
                    }
                }));
            } else {
                commit(SET_GA_AUTHORIZED, false);
                console.log('Not authorized');
            }

            dispatch('fetchGAProfilesData');
        },
        /**
         * The action that fetches linked GA profiles data by api
         * and put it into the store in case of successful response.
         */
        async fetchGAProfilesData ({ commit, getters }) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(i18n.t('upgrade-for-google', {service: i18n.t('google-analytics')}));
                return false;
            }

            const res = await gaApi.fetchGaProfiles();

            if (res.data.response && res.data.response !== 'success') {
                throw new Error('Error on get GA profiles data');
            }
            
            let GAProfilesData = [];
            if(res.data.data){
                GAProfilesData = res.data.data.map(el => {
                    return {
                        id: el.id,
                        domain: el.domain_url,
                        projects: el.associated_categories[0],
                        profileId: el.profile_id,
                        viewkey: el.viewkey,
                    };
                })
            }
            commit(SET_GA_PROFILES_DATA, GAProfilesData);
        },
        /**
         * The action that adds new GA profile by api
         */
        async linkGAProfile ({ getters, dispatch }, { profile, projects }) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(i18n.t('upgrade-for-google', {service: i18n.t('google-analytics')}));
                return false;
            }

            const { getFullProjectName } = getters;
            profile = profile.split('|');

            const queryData = {
                data: {
                    profile_id: profile[0],
                    url: profile[1],
                    pre_start_date: '2018-10-09',// @TODO right dates
                    pre_end_date: '2018-11-07',// @TODO right dates
                    projects: []
                },
            };

            if (projects.length) {
                for (let i = 0; i < projects.length; i++) {
                    queryData.data.projects.push(getFullProjectName(projects[i]))
                }
            }

            await gaApi.createGaProfile(queryData);

            dispatch('fetchGAProfilesData');
        },
        /**
         * The action that removes selected GA profile by api
         * and if response is successful - mutates the store
         */
        async removeGAProfile ({ getters, commit }, id) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(i18n.t('upgrade-for-google', {service: i18n.t('google-analytics')}));
                return false;
            }

            const GAProfilesData = _.cloneDeep(getters.getGAProfilesData);
            await gaApi.removeGaProfile(id);

            // Removes GAProfile from store
            const indexForRemove = GAProfilesData.findIndex(el => el.id === id);

            if (indexForRemove !== -1) {
                GAProfilesData.splice(indexForRemove, 1);
            }

            commit(SET_GA_PROFILES_DATA, GAProfilesData);
        },
        async updateGAProfile ({ getters, commit }, { id, projects }) {
            if (!getters.getPermissionData('google_integrations')) {
                toastr.w(i18n.t('upgrade-for-google', {service: i18n.t('google-analytics')}));
                return false;
            }

            const GAProfilesData = _.cloneDeep(getters.getGAProfilesData);

            const queryData = {
                data: {
                    projects: []
                },
            };

            if (projects.length) {
                for (let i = 0; i < projects.length; i++) {
                    queryData.data.projects.push(projects[i])
                }
            }

            await gaApi.updateGaProfile(id, queryData);

            // Put updated GA profile into store
            const indexForUpdate = GAProfilesData.findIndex(el => el.id === id);
            GAProfilesData[indexForUpdate].projects = projects.join(';');
            commit(SET_GA_PROFILES_DATA, GAProfilesData);
        },
    }
};

export default googleAnalytics;
