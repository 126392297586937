const enSettings = {
    'alerts-settings': 'ALERT SETTINGS',
    'alerts-settings-small': 'Alerts Settings',
    'amount-keywords-per-page': 'Keywords to Display per page',
    'starting-rank': 'Starting Rank',
    'search_intents': 'Search Intent',
    'category-date-title': 'Display Project Creation Date',
    'chart-height': 'Chart Height',
    'company-information-setting': 'Display Company Information in ViewKeys',
    'company-information-pdf-setting': 'Display Company Information',
    'custom-subdomain': 'Custom Subdomain',
    'support-custom-subdomains': 'We can support a native CNAME custom subdomain integration (for accounts with 5,000+ keywords)',
    'daily-values': 'Compare daily Values',
    'contact-us': 'Contact Us',
    'date-added': 'Date Added',
    'date-format': 'Date Format',
    'disable-responsive': 'Disable Mobile Responsive',
    'display-keywords-totals': 'Display Keywords totals',
    'do-not-show-metrics': 'Do not show metrics',
    'domain-url-setting': 'Display Project Domain as Ranking URL',
    'email-settings': 'Email Settings',
    'google-analytics-chart': 'Google Analytics Charts',
    'ranking-area-chart': 'Display Ranking Area Chart in Project Dashboard',
    'serp-features': 'SERP Features',
    'google-rank': 'Google Rank',
    'google-ranking-page': 'Google Ranking Page',
    'graph-settings-spyglass': 'Graph/Settings/Spyglass',
    'project-metrics': 'Project Metrics',
    'project-name-asc': 'Project Name Ascending',
    'project-name-desc': 'Project Name Descending',
    'project-table-columns': 'Project Table Columns',
    'project-table-settings-description': 'Customize your display settings here, play around with settings until they fit your style.',
    'project-table-settings': 'Project Table Settings',
    'show-in-viewkeys': 'Show in ViewKey',
    'show-in-pdf': 'Show in PDF',
    'keyword-settings': 'Local keyword settings',
    'kwtotals-master': 'Display for master account',
    'kwtotals-admin': 'Display for <span>&nbsp;addon user&nbsp;</span> - admin access',
    'kwtotals-regular': 'Display for <span>&nbsp;addon user&nbsp;</span> - regular access',
    'kwtotals-view': 'Display for <span>&nbsp;addon user&nbsp;</span> - view only',
    'life-change': 'Lifetime Change (vs Start Rank)',
    'list-projects': 'List Projects',
    'reporting-currency': 'Account Reporting Currency',
    'help-reporting-currency': 'Set the currency that you would like your CPC and other keyword metric values to be reported in for all your projects.',
    'metric-ranges': 'Metric Ranges',
    'monthly-values': 'Compare monthly values',
    'ms': 'Search Volume',
    'competition': 'Competition',
    'cpc': 'Average Cost Per Click',
    'visibility': 'Visibility',
    'estimated_traffic': 'Estimated Traffic',
    'cpc-short': 'CPC',
    'near-geolocation': 'Search Location',
    'notes-in-reports': 'Display Project Notes',
    'one-day-change': '1 Day Change',
    'other-settings': 'Other Settings',
    'pdf-pages': 'Split Up PDF Reports into Multiple Pages',
    'rank-drop-setting': 'Fill in Rank Drop Gaps in Charts',
    'new-keywords-table': 'Use new keywords table (beta)',
    'report-settings-updated': 'Report settings updated',
    'seo-comp': 'Search Results',
    'source': 'Source',
    'settings': 'Settings',
    'seven-day-change': '7 Day Change',
    'sort-table-by': 'Sort Tables by',
    'start-values': 'Compare start values',
    'groups-setting': 'Display Groups in Parent Projects',
    'generate-historical-graphs-in-pdf': 'Generate Historical Charts',
    'limit-info-about-historical-graphs-in-pdf': 'Charts will only show for projects with {count} keywords or less',
    'table-width': 'Maximum table width',
    'tags-uppercased': 'Tags',
    'thirty-day-change': '30 Day Change',
    'viewkey-settings': 'ViewKey Settings',
    'weekly-values': 'Compare weekly values',
    'invalid-value': 'Invalid value',
    'same-values': 'Same values!',
    'full-name': 'Full Name',
    'name-surname': 'Name Surname',
    'contact-number': 'Contact Number',
    'change-password': 'Change Password',
    'save-changes': 'Save Changes',
    'save': 'Save',
    'account-subtitle': 'Account',
    'country': 'Country',
    'country-state': 'Country State',
    'country-state-region': 'Country / Region / State',
    'long-name-error': 'The limit is 50 characters, please shorten the value',
    'invalid-username': 'Please, enter a valid name',
    'cant-be-empty': 'Can’t be empty',
    'value-cant-be-empty': '{value} can’t be empty',
    'error-add-keywords': 'The Keyword length exceeds the allowed limit. \n {value}',
    'error-add-keywords-title': 'Keyword(s) not added...',
    'only-numbers': 'Only numbers are accepted',
    'only': 'Only',
    'phone-min-max': 'Should be 10-12 digits in length',
    'view-only': 'View Only',
    'addon-user': 'Addon User',
    'admin-access': 'Admin Access',
    'restrictions-placeholder-for-admin-access': 'Have access to all accounts',
    'set-password-for-addon': 'Set the password for the Shared Access User.',
    'restrict-projects-for-addon': 'Select which Projects the Shared Access Users will not have access to.',
    'addon-accesses': 'VIEW ONLY: Can only view projects and data. ADD-ON USER: Can manage all Keywords and has editing privileges. ADMIN USER: Full access user.',
    'addon-users-updated': 'Addon user(s) successfully updated',
    'update-password': 'Update Password',
    'new-user': 'New User',
    'restrictions': 'Restrictions',
    'master-account': 'Keyword Master Account',
    'select-restrictions': 'Select Restrictions',
    'invalid-email': 'Invalid email address',
    'login-form-confirm-password': 'Passwords don’t match',
    'button-loader-text': 'Please, do not refresh the page...',
    'select-project-restrictions': 'Select Project Restrictions',
    'restrict-following-project': 'Restrict to the following projects',
    'enter-password-for': 'Please enter a password for {email}',
    'new-password': 'New Password',
    'alert-email': 'Alert Email',
    'alert-meat': 'Alerts Frequency',
    'alert-per-page': 'Alerts Per Page',
    'email-reports-settings': 'Email and Reports Settings',
    'send-from': 'Send From',
    'sender-name': 'Sender Name',
    'sender-name-description': 'Modifying the \'Send From\' email can result the report ending up in the JUNK folder',
    'subject': 'Subject',
    'subject-placeholder': 'Keyword.com Report: %category%',
    'message': 'Message',
    'attached-emails-placeholder': 'Your report is attached to this email',
    'message-description': 'Use %category% for category name',
    'attachment-format': 'Attachment Name Format',
    'company-info': 'Company Info',
    'company-name': 'Company Name',
    'vat-number': 'VAT/GST Number',
    'street-address': 'Street Address',
    'suite-unit': 'Suite / Unit',
    'town-city': 'Town / City',
    'postal-code': 'Postal Code',
    'company-name-placeholder': 'Company Name',
    'company-url-link': 'Company URL Link',
    'company-url': 'Company URL',
    'company-logo': 'Company Logo URL',
    'logo-url': 'Logo URL',
    'company-description': 'Company Description',
    'company-description-placeholder': 'Keyword.com Rank Tracking Service - Whitelabel Reports',
    'long-value-error': 'The limit is {limit} characters, please shorten the {value}',
    'invalid-value-of': 'Invalid value of {value}',
    'invalid-url-of': 'Invalid URL of {value}',
    'serpbook-reports': 'Keyword.com Reports',
    'your-company-name': 'Your Сompany Name',
    'invalid': 'Invalid {value}',
    'extra-wide-note': 'Note: you may not see the larger affect on smaller screens',
    'remove': 'Remove',
    'customize-table': 'Customize Table',
    'project-settings': 'Project Settings',
    'select-table-columns': 'Select Table Columns',
    'brand-settings': 'Brand Settings',
    'schedule-report': 'Schedule Report',
    'hide-verified': 'Hide "Verified by Keyword.com"',
    'hide-share-options': 'Hide share options',
    'hide-report-date': 'Hide "Report Date"',
    'use-whitelabel': 'Use Whitelabel Domain (seranktracker.com)',
    'warning-100-rows-exceed': 'Warning: we recommend not to exceed 100 rows per page, otherwise, it may lead to slowness on our platform',
    'company-desc-placeholder-preview': 'Company description - say hi to your customers and prospects!',
    'global-search-volume': 'Monthly Search Volume for Google.com Keywords',
    'show-global': 'Show Global',
    'show-usa': 'Show USA',
    'kw-limit-reached': 'The total number of keywords in this project(s) will exceed your plan',
    'viewkey-brand-settings': 'ViewKey Brand Settings',
    'viewkey-project-settings': 'ViewKey Project Settings',
    'settings-view-key-logo-placeholder': 'https://app.keyword.com/images/logo.png',
    'settings-view-key-url-placeholder': 'https://app.keyword.com/',
    'pdf-settings-company-information': 'To change Company Information go to ViewKey Brand Settings',
    'customize-table-columns': 'Customize Table Columns',
    'email-notification-settings-updated': 'Email Notification settings were successfully updated.'
};

export default enSettings;
