import { setApiToken } from '@/api/client';
import apiFactory from '@/api';
import router from '@/routes';
import store from '@/store';
import { GTM_EVENTS } from '@/constants';
import { cookieGetters, cookieManager } from '@/helpers/cookieManager';


const redirectIfExpired = (isExpired, redirect, toastr) => {

    if (isExpired && redirect) {
        router.push({ name: 'AccountDetails' });
        if(toastr){
            toastr.w(i18n.t('billing-subscription-error'));
        }

        return true;
    }

    return false;
}

const redirectIfRestricted = (isRestricted) => {

    if (isRestricted) {
        router.push({ name: 'settingsAccount' });

        return true;
    }

    return false;
}

const saveLoginData = function (data) {
    if (data.data.attributes.verified) {
        //Set pagination type for metrics for users
        store.dispatch('setIsAuthUser',true);
        const overviewUsers = process.env.PAGINATED_WITH_API_OVERVIEW_USERS || '';
        const paginateInFrontEnd = overviewUsers.split(',').includes(data.data.id);
        store.dispatch('setOverviewPaginateWithAPI', !paginateInFrontEnd);
        store.dispatch('setIsNotStripeUser', data.data.attributes.is_not_stripe_user);
        store.dispatch('setPaymentDetailsLoaded', true);
        store.dispatch('saveUser', data);
        store.dispatch('saveSettings', data);
        setApiToken(cookieGetters.getApiKey());
        store.dispatch('fetchCurrencies');
        const isFreePriceType = data.data.attributes?.subscription?.price_type === 'free';

        const redirect = !isFreePriceType && !data.data.attributes?.subscription?.expire_post_trial;

        if (!redirectIfExpired(store.getters.getIsExpired, redirect)) {
            /**
             * GTM custom event successfully_logged_in
             */
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: GTM_EVENTS.SUCCESSFULLY_LOGGED_IN,
                uid: data.data.attributes.user_id,
            });
            router.push({ name: 'Overview' });
        }
    } else {
        router.push({ name: 'thankYou', query: { email: data.data.id } });
    }
};

const logout = function () {
    const legacyApi = apiFactory.get('legacy');
    cookieManager.remove('api_key', null, process.env.NODE_ENV === 'production' ? 'keyword.com' : 'serpbook.me');
    cookieManager.remove('api_key');
    cookieManager.remove('onboarding');
    cookieManager.remove('showall');
    cookieManager.remove('userProfile');
    store.dispatch('setTutorialMode');
    setApiToken(null);

    if (process.env.NODE_ENV === 'production') {
        legacyApi.logout().catch(err=>{
           return err
        })
    }

    router.push({ name: 'login' });

    if (window.Intercom) {
        window.Intercom('shutdown');
    }

    const posthog = Vue.prototype.$posthog

    if (posthog) {
        posthog.reset();
    }
};

const version = {
    packageVersion: process.env.VERSION,
};

const checkRestrictedUser = () => {
    const restricted = cookieGetters.getIsRestricted();
    if(restricted) {
        try {
            return JSON.parse(restricted);
        } catch (error) {
            return false;
        }
    }
    return false;
}

export { version, logout, saveLoginData, redirectIfExpired, redirectIfRestricted, checkRestrictedUser };
