<template>
    <div class='viewkey-chart-wrapper' v-resize="() => debouncedHandler(resizeChart)">
        <keywords-in-top-chart
            :chart="chart"
            :project="projectData"
            :loading="false"
            :isChartDisplayed="true"
            @setChart="setChart"
            :key="`keywords_in_top_${chartKey}`"
            showLatest
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import KeywordsInTopChart from '@/components/common/KeywordsInTopChart.vue';
import resize from 'vue-resize-directive';
import { detectIsSurferSEO } from '@/helpers';

export default {
    name: 'SharedKeywordsInTopBlock',
    components: {
        'keywords-in-top-chart': KeywordsInTopChart
    },
    directives: {
        resize,
    },
    data() {
        return {
            chart: null,
            projectData: {},
            fetched: false,
            timeoutId: null,
            isFetching: false,
        };
    },
    computed: {
        ...mapGetters([
            'getCurrentProject',
            'getViewkeyKeywordsInTopPositionsData',
            'getViewkeyChartIsFetching',
            'getActiveTag',
        ]),
        chartKey(){
            return this.getActiveTag?.id || this.getCurrentProject?.id;
        },
    },
    watch: {
        getCurrentProject: function() {
            this.fetchData();
        },
    },
    mounted(){
        if(!this.fetched && this.getCurrentProject?.project_id){
            this.fetchData();
        }
    },
    methods: {
        ...mapActions([
            'fetchViewkeyKeywordsInTopPositions',
        ]),
        debouncedHandler: _.debounce(fn => fn(), 50),
        resizeChart() {
            if (this.chart) {
                window.dispatchEvent(new Event('resize'));
            }
        },
        fetchData(){
            if (this.isFetching) return;

            this.isFetching = true;
            this.fetchViewkeyKeywordsInTopPositions().then((data) => {
                if(data){
                    this.init(this.getViewkeyKeywordsInTopPositionsData);
                }
            }).finally(() => {
                this.isFetching = false;
                if(detectIsSurferSEO()){
                    this.timeoutId = setTimeout(() => {
                        this.fetchData();
                    }, 15000);
                }
            });
        },
        setChart(value) {
            this.chart = value;
        },
      
        init(project){
            if (!project.data) return;

            this.projectData = {
                charts: project.data,
                id: this.getCurrentProject.id,
                categories: project.categories
            };
        }
    },
    beforeDestroy(){
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
        this.projectData = {};
    }
};
</script>

<style lang='scss' scoped>
    .viewkey-chart-wrapper {
        width: calc(100% - 10px);
        height: 100%;
        min-height: 228px;

        .overview-chart-wrapper {
            height: 228px;
        }
    }
</style>
