import {
    PUBLIC_ROUTES,
    MOBILE_WIDTH_RESOLUTION_PHONE
} from '@/constants';
import store from '@/store';
import { cookieGetters, cookieManager } from '@/helpers/cookieManager';

const guards = {
    root: (to, from, next) => {
        if (MOBILE_WIDTH_RESOLUTION_PHONE < window.screen.width) {
            next({ name: 'Overview' });
        } else {
            next();
        }
    },
    overview: (to, from, next) => {
        store.dispatch('firstRefreshesCheck', { overview: true });
        next();
    },
    global: (to, from, next) => {
        // check if not authenticated user
        if (!cookieManager.isKey('api_key') && !PUBLIC_ROUTES.includes(to.name)) {
            next({ name: 'login' });
            return;
        }
        // check if user is restricted
        if(store.getters.getIsRestrictedAccessUser
            && ![
                ...PUBLIC_ROUTES,
                'settingsAccount',
                'settingsPlan',
                'settingsAccountTable',
                'Pricing',
                'AccountDetails',
                'settingsSharedAccess',
                'settingsNotifications'
            ].includes(to.name)
        ){
            next({ name: 'settingsAccount' });
            return;
        }

        const isPaidPlan = store.getters.getUserData?.is_free_plan === false;

        if ((cookieGetters.getExpireAt() == 'null' &&
            ![...PUBLIC_ROUTES, 'Pricing', 'AccountDetails', 'contactUs'].includes(to.name)
        ) || (!!store.getters.getUserData.userId && store.getters.getIsExpired && !store.getters.getExpirePostTrial && isPaidPlan && to.meta.requiresSubscription)
        ) {
            next({ name: 'AccountDetails' });
            return;
        }

        if (cookieGetters.getOnboarding() == 'false' &&
            ![...PUBLIC_ROUTES, 'Pricing', 'AccountDetails', 'contactUs', 'Tutorial'].includes(to.name)
        ) {
            next({ name: 'Tutorial' });
            return;
        }

        next();
    },
    keywordList: (to, from, next) => {
        localStorage.setItem('lastOpenedProjectId', to.params.id);
        next();
    },
    logPage: (to, from, next) => {
        if (cookieGetters.getApiKey()) {
            next({ name: 'root' });
        } else {
            next();
        }
    },
    tutorial: (to, from, next) => {
        if (cookieGetters.getOnboarding() == 'false') {
            next();
        } else {
            next({ name: 'root' });
        }
    },
    notViewOnly: (to, from, next) => {
        if (!store.getters.isViewOnlyUser) {
            next();
        } else {
            next({ name: 'Overview' });
        }
    },
    isMaster: (to, from, next) => {
        if (store.getters.isMasterUser) {
            next();
        } else {
            next({ name: 'settingsSharedAccess' });
        }
    },
    isAdmin: (to, from, next) => {
        if (store.getters.isAdminOrMasterUser) {
            next();
        } else {
            next(false);
        }
    },
};

export default type => guards[type];