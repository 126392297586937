<template>
    <div
        ref="tableDynamic"
        :class="keywordsTableClasses"
        data-cy="keywords-table-dynamic"
    >
        <vue-custom-scrollbar
            wrapper-query-selector=".keywords-table_dynamic"
            header-query-selector=".keywords-table__header"
        >
            <div class="keywords-table__header">
                <div
                    id="dynamicTableHeaderRow"
                    class="header-row"
                >
                    <div
                        v-for="field in dynamicColumns"
                        :key="field.name"
                        :class="headerClasses(field)"
                        :style="getColumnStyle(field)"
                        @click.stop="headerClick(field)"
                    >
                        <div
                            v-if="field.name === 'cpc' && codeVisible"
                            class="column-header__title"
                            @mouseover="handleMouseover(field, $event, true)"
                            v-html="`${field.title}/${getCurrentCurrency.code}`"
                        />
                        <div
                            v-else
                            class="column-header__title"
                            @mouseover="handleMouseover(field, $event, true)"
                            v-html="field.title"
                        />

                        <span
                            v-if="isGSCColumns(field.name)"
                            class="kw kw-information"
                            @mouseover="tooltip({text: $t(`i-${field.name}`), event: $event})"
                            @click.stop="openNewTab(field.helperUrl)"
                        />
                        <span
                            v-else-if="!withoutIFields(field.name)"
                            class="kw kw-information"
                            @mouseover="tooltip({text: $t(`i-${field.name}`), event: $event})"
                        />

                        <div
                            class="column-header__sort-container"
                            :class="getDisplaySetting('sort_by_order')"
                        >
                            <span
                                v-if="field.sortField === getDisplaySetting('sort_by')"
                                class="kw kw-sort"
                                @mouseover="tooltip({text: `Sort by ${getDisplaySetting('sort_by_order')}ending order`, event: $event})"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="keywords-table__body">
                <virtual-list
                    id="virtual-dynamic"
                    data-cy="keywords-table-dynamic"
                    :debounce="50"
                    :remain="50"
                    :pagemode="true"
                    :size="VIRTUAL_LIST_ITEM_HEIGHT"
                    :wclass="['both', 'right'].includes(dynamicShadow()) ? 'virtual-list_shadow' : ''"
                >
                    <div
                        v-for="(keyword, index) in items"
                        :key="index"
                        :class="tableRowClasses(keyword.isfav)"
                        @click="setShowKeywordAction(keyword.auth)"
                    >
                        <h4
                            v-if="title(keyword, index)"
                            class="table-row__title"
                            v-html="title(keyword, index)"
                        />

                        <div
                            :id="`row-dynamic-${keyword.id}`"
                            :class="tableRowInnerClasses(index, getBodyCheckboxChecked(keyword.id))"
                            :style="tableRowInnerStyles(keyword.id, title(keyword, index))"
                            @mouseover="setHoverState(keyword.id)"
                            @mouseleave="removeHoverState(keyword.id)"
                        >
                            <div
                                v-for="field in dynamicColumns"
                                :key="field.name"
                                :class="fieldDataClasses(field, isPdf)"
                                :style="getColumnStyle(field)"
                                @mouseover="handleMouseover(field, $event, false)"
                                @click.ctrl="e => handleBodyCheckoboxChange(keyword.id, 'Keyword', index, e, items)"
                                @click.meta="e => handleBodyCheckoboxChange(keyword.id, 'Keyword', index, e, items)"
                                @click.shift="e => handleColumnClick(keyword, index, e, items)"
                            >
                                <div class="row-wrap" data-cy="keyword-row">
                                    <div v-if="isGSCColumns(field.name) || field.name==='gpage'"
                                        class="table-cell__content table-cell__content-grank"
                                    >
                                        <div class="table-cell__text table-cell__text_ranks">
                                            <rotating-update-icon v-if="keyword[field.name] === null" />
                                            <span v-else-if="keyword[field.name] === -1" class="empty-value">&mdash;</span>
                                            <span
                                                v-else-if="
                                                    keyword[field.name] === 0 &&
                                                    field.name === 'gpage'
                                                "
                                                style="color: #c1c4cd"
                                                >> 10</span
                                            >
                                            <template v-else>
                                              {{ keyword[field.name].toLocaleString('en') }}
                                              {{ field.name === 'ctr' ? '%' : '' }}
                                            </template>
                                        </div>
                                    </div>
                                    <template v-else-if="simpleFields(field.name)">
                                        <template v-if="isPeriodRow(field.name)">
                                            <span :class="periodIconClasses(field, keyword)" />
                                            <span
                                                class="period-value"
                                                v-html="processedField(field.name, keyword, true)"
                                            />
                                        </template>
                                        <span
                                            v-else
                                            v-html="processedField(field.name, keyword, true)"
                                        />
                                    </template>

                                    <div
                                        v-else-if="geoFields(field.name)"
                                        class="table-cell"
                                    >
                                        <div class="table-cell__content table-cell__content_cut">
                                            <div
                                                class="table-cell__text"
                                                @mouseover="tooltip({text: keyword[field.name], event: $event})"
                                                v-text="keyword[field.name]"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        v-else-if="field.name === 'grank'"
                                        class="table-cell"
                                    >
                                        <div class="table-cell__content table-cell__content-grank">
                                            <div
                                                class="table-cell__text table-cell__text_ranks"
                                                v-html="processedField(field.name, keyword, true)"
                                            />
                                        </div>
                                    </div>

                                    <component
                                        :is="`${field.name}-field`"
                                        v-else
                                        :route-name="$route.name"
                                        :keyword="keyword"
                                        :is-multi-domain="isMultiDomain"
                                        :value.sync="keywordForStart.start"
                                        :classes="getClasses(field.name)"
                                        @open-input="openInput(keyword)"
                                        @set-active-tags="setActiveTagsWithUpdate"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </virtual-list>
            </div>
        </vue-custom-scrollbar>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import virtualList from 'vue-virtual-scroll-list';

    import { isSafari } from '@/helpers/device-detection';
    import { processedField } from '@/helpers/keywordTablesHelper';

    import DashboardMixin from '@/mixins/DashboardMixin';
    import KeywordsTableMixin from '@/mixins/KeywordsTableMixin';
    import ToolTipRenderMixin from '@/mixins/ToolTipRenderMixin';

    import Hyphen from 'components/ui-elements/Hyphen';
    import RotatingUpdateIcon from 'components/ui-elements/RotatingUpdateIcon';
    import UrlField from 'components/main-table/fields/UrlField';
    import TagsField from 'components/main-table/fields/TagsField';
    import StartField from 'components/main-table/fields/StartField';
    import SerpFeaturesField from 'components/main-table/fields/SerpFeaturesField';
    import SearchIntentsField from 'components/main-table/fields/SearchIntentsField';
    import VueCustomScrollbar from 'components/extended/VueCustomScrollbar';

    import { KW_TABLE_COLUMNS, VIRTUAL_LIST_ITEM_HEIGHT } from '@/constants';

    export default {
        name: 'KeywordsTableDynamic',
        components: {
            'hyphen': Hyphen,
            'start-field': StartField,
            'tagsSplited-field': TagsField,
            'url-field': UrlField,
            'serpFeatures-field': SerpFeaturesField,
            'searchIntents-field': SearchIntentsField,
            'virtual-list': virtualList,
            'vue-custom-scrollbar': VueCustomScrollbar,
            'rotating-update-icon': RotatingUpdateIcon,
        },
        mixins: [
            DashboardMixin,
            KeywordsTableMixin,
            ToolTipRenderMixin,
        ],
        props: {
            items: {
                type: Array,
                required: true,
            },
            isPdf: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        data () {
            return {
                VIRTUAL_LIST_ITEM_HEIGHT,
                showAllTags: null,
                keywordForStart: {
                    kwid: '',
                    auth: '',
                    start: '',
                },
            };
        },
        computed: {
            ...mapGetters([
                'getViewkeyView',
                'getCurrentProject',
                'getDisplaySetting',
                'getDisplaySettings',
                'getListOfKeywordsDomains',
                'getOriginalItems',
                'getTableColumns',
                'getIsAllCheckboxesSelected',
                'getGlobalSearchQuery',
                'getViewkeyHasChildren',
                'getShareViewHashData',
                'getOtherSetting',
                'getProjectByName',
                'getCurrentCurrency',
            ]),
            isMultiDomain() {
                return this.getListOfKeywordsDomains.length > 1;
            },
            dynamicColumns () {
                return this.getTableColumns.filter(el => !KW_TABLE_COLUMNS.STATIC.includes(el.name));
            },
            keywordsTableClasses () {
                return {
                    'keywords-table keywords-table_dynamic': true,
                };
            },
            codeVisible() {
                return (
                    !this.getGlobalSearchQuery &&
                    (this.getShareViewHashData !== null ||
                        !this.getViewkeyView ||
                        !this.getViewkeyHasChildren) &&
                    (this.getOtherSetting('subcat') !== 'show' ||
                        !this.getCurrentProject ||
                        !this.getProjectByName(this.getCurrentProject)?.project
                            ?.children)
                );
            },
        },

        methods: {
            ...mapActions([
                'fetchKeywords',
                'addActiveTag',
                'updateKeywordBaseline',
                'updateUserDisplaySettings',
                'setSelectedKeywords',
                'setAllCheckboxesSelected',
                'toggleKeywordSelection',
                'toggleKeywordsTableSorter',
                'setShowKeywordAction',
                'fetchKeywordsFromSearch',
            ]),
            processedField,
            // checkProcessedField (field, keyword, isTooltipDisplayed = true) {
            //   if (this.hideGSCColumn(field)) {
            //     return "";
            //   }

            //   return this.processedField(field, keyword, isTooltipDisplayed);
            // },

            setActiveTagsWithUpdate (tag) {
                if(
                  this.$route.name === 'shareViewkeyWithHash' ||
                  this.$route.name === 'shareKeywordsTableWithHash'
                ) return;

                this.addActiveTag(tag);
            },
            async headerClick ({sortField}) {
                if (!sortField || this.hideGSCColumn(sortField)) {
                  return "";
                }

                this.toggleKeywordsTableSorter(sortField);
                if(this.getGlobalSearchQuery) {
                    this.fetchKeywordsFromSearch();
                }else {
                  if (!this.getViewkeyView) {
                      this.updateUserDisplaySettings({
                          setting: 'sort_by',
                          value: this.getDisplaySetting('sort_by'),
                          showMsg: false,
                      });
                      this.updateUserDisplaySettings({
                          setting: 'sort_by_order',
                          value: this.getDisplaySetting('sort_by_order'),
                      });
                  }
                  await this.fetchKeywords({id: this.getCurrentProject.id, password: this.getCurrentProject.password,});
                }
            },
            withoutIFields (field) {
                return KW_TABLE_COLUMNS.WITHOUT_I.includes(field);
            },
            simpleFields (field) {
                return KW_TABLE_COLUMNS.SIMPLE.includes(field);
            },
            geoFields (field) {
                return KW_TABLE_COLUMNS.GEO.includes(field);
            },
            isPeriodRow (row) {
                return KW_TABLE_COLUMNS.PERIODS.includes(row);
            },
            setAllTags (keywordAuth) {
                if (this.showAllTags === keywordAuth) {
                    this.hideAllTags();
                    return;
                }

                this.showAllTags = keywordAuth;
            },
            hideAllTags () {
                this.showAllTags = null;
            },
            toggleInput (keyword) {
                if (!keyword) {
                    this.keywordForStart = {
                        kwid: '',
                        auth: '',
                        start: '',
                    };

                    return;
                }

                this.keywordForStart = {
                    kwid: keyword.id,
                    auth: keyword.auth,
                    start: keyword.start,
                };
            },
            openInput (keyword) {
                // if input has already opened
                if (this.keywordForStart.kwid) {
                    return;
                }

                this.toggleInput(keyword);
                this.$nextTick(() => {
                    if (this.$refs.keywordStartInput) {
                        this.$refs.keywordStartInput.focus();
                    }
                });
            },
            async saveAndCloseInput () {
                try {
                  await this.updateKeywordBaseline(this.keywordForStart);
                  this.toggleInput();
                } catch (error) {
                  //
                }
            },
            handleColumnClick ({id}, index, e, items) {
                this.setAllCheckboxesSelected(false);
                // this.handleDashboardRowSelect(id, 'Keyword', index, e, items);
                const store = {
                    selectedGetter:'getSelectedAuth',
                    selectedAction: 'selectKeywordRow',
                    selectedItemsAction:  'setSelectedKeywords',
                };

                this.applyShiftLogic(e, id, index, items, store);
            },
            headerClasses (field) {
                return {
                    [field.titleClass]: true,
                    'column-header_safari': isSafari(),
                };
            },
            handleBodyCheckoboxChange (id) {
                this.setAllCheckboxesSelected(false);
                this.setSelectedKeywords([id]);
            },
            getClasses (name) {
                return {
                    'tag-field-disabled':
                        name === 'tagsSplited' &&
                        (this.$route.name === 'shareViewkeyWithHash' ||
                            this.$route.name === 'shareKeywordsTableWithHash'),
                    };
            },
            openNewTab(url) {
                window.open(url, "_blank");
            },
        },
    }

</script>

<style lang="scss">
// important class for overflow shadows of dynamic table
.column-header_with-shadow {
  &:before {
    background: linear-gradient(to left, rgba(31, 63, 97, 0.07) 0%, rgba(31, 63, 97, 0) 97.37%);
    content: '';
    height: 100%;
    position: absolute;
    right: 100%;
    width: 8px;
  }
}

.keywords-table_dynamic {
  @extend .custom-scroll;
  border-top-right-radius: 4px;
  flex: 1 1 auto;
  overflow: auto;
  // header
  border-right: 1px solid #E1E8EF;

  .column-header {
    &:first-child {
      border-left: none !important;
      border-radius: 0;
    }

    &:last-child {
      border-right: none;
    }
  }

  .column-header_borderless {
    border-right: transparent !important;

    & + .column-header:not(.column-header_borderless) {
      border-left: 1px solid #E1E8EF;
    }
  }

  .column-header_gpage {
    .column-header__title {
      display: flex;
      flex-flow: row nowrap;
      margin-left: 0;
    }

    .column-header__icon {
      font-style: normal;
      outline: transparent;
      padding-left: 16px;
      width: 100%;
    }
  }

  .table-cell__icon {
    position: relative;

    i {
      display: inline-block;
      font-size: 14px;
      margin-top: 6px;
    }

    span.kw-flag {
      color: #4DB645;
      font-size: 10px;
      font-weight: 700;
      left: -10px;
      margin-top: 0px !important;
      position: absolute;
      top: -4px;
    }
  }

  // body
  .table-row__title {
    color: transparent;
    user-select: none;
    width: 0;
  }

  .column-data {
    &:first-child {
      border-left: none !important;
    }

    &:last-child {
      border-right: none;
    }
  }

    .table-cell__text {
        &.table-cell__text_ranks {
            overflow: visible !important;
        }
    }

  .column-header_url,
  .column-data_url {
    min-width: 250px;

    &.column-header_safari,
    &.column-data_safari {
      padding-right: 47px !important;
    }
  }

  .column-data_borderless {
    border-right: transparent !important;

    & + .column-data:not(.column-data_borderless) {
      border-left: 1px solid #E1E8EF;
    }
  }

  .column-data_clicks,
  .column-data_impressions,
  .column-data_ctr,
  .column-data_visibility,
  .column-data_estimated_traffic,
  .column-data_cpc,
  .column-data_competition,
  .column-data_searchvolume,
  .column-data_seocomp {
    span {
      letter-spacing: .2px;
      text-align: right;
    }
  }

  .column-data_timestamp {
    letter-spacing: 0.1px;
  }

  .column-data_near {
    min-width: 100px;
  }

  .column-data_gmb {
    min-width: 67px;
  }

  .column-data_search_intents {
      .row-wrap {
          height: 100%;
      }
  }

  .column-data_period {
    .row-wrap {
      display: flex;
      width: 100%;
      position: relative;

      .period-value {
        width: 100%;
        white-space: nowrap;
        text-align: right;

        span.dot-negative,
        span.dot-positive {
          margin-left: auto;
          margin-right: 3px;
        }
      }

      &:before {
        position: absolute;
        left: 15px;
      }
    }

    span.icon-arrow_4 {
      font-size: 10px;
      line-height: 16px;
      margin-right: 5px;
      width: 9px;
    }

    span.icon-arrow_4.icon-rotate-down {
      color: kw-color(kw-red, 1);
    }

    span.icon-arrow_4:not(.icon-rotate-down) {
      color: kw-color(kw-green, 1);
    }
  }

  .column-data_tags {
    justify-content: flex-start !important;
    position: relative;

    .table-cell {
      max-width: 109px;
    }
  }
  .empty-value {
      cursor: default;
  }
  .column-data_clicks,
  .column-data_impressions,
  .column-data_ctr,
  .column-data_grank {
      overflow: auto;

      .kw-refresh-ccw {
          color: kw-color(kw-grey, 1);
          font-size: 16px;
          width: auto;
          font-weight: 700;
          animation: infiniteRotating 2s linear infinite;
      }
    .table-cell__icon {
      margin-right: 2px;

      i {
        background-repeat: no-repeat;
        background-size: contain;
        height: 14px;
        margin-top: 8px;
        width: 10px;
      }
    }
  }

  .column-data_serp_features {
    padding: 0 10px !important;
  }
}

.keywords-table_grownup {
  border-left: 1px solid #E1E8EF;
  border-top-left-radius: 4px;

  .column-header:first-child {
    border-top-left-radius: 4px;
  }
}

.empty-value {
  color: #d3d3d3;
}

.tag-field-disabled {
  pointer-events: none;
}

@media (max-width: 959px) {
  .keywords-table_dynamic {
    border-top-right-radius: 0;

    .column-header {
      &:last-child {
        border-right: none;
        border-top-right-radius: 0;
      }

      &:not(.column-header_checkbox) {
        padding: 0 10px;
      }
    }

    .table-row {
      .column-data {
        &:last-child {
          border-right: none;
        }

        &:not(.column-data_checkbox) {
          padding: 0 10px;
        }

        &.column-data_url {
          padding: 0 10px 0 25px;
        }
      }
    }

  }
}
</style>
