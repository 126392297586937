import { switchDateFormat } from './format-date-service';
import { KEYWORD_SOURCES } from '@/constants';
import i18n from '@/i18n';

const unchangedDataHtml = isTooltipDisplayed => `<span ${isTooltipDisplayed && `data-important-tooltip="${i18n.t('not-changed-data')}"`} class="empty-value">&mdash;</span>`;
const unavailableDataHtml = isTooltipDisplayed => `<span ${ isTooltipDisplayed && `data-important-tooltip="${i18n.t('data-not-available')}"`} class="empty-value">??</span>`

function showTrendOrDot (trend, start, isTooltipDisplayed) {
  return start === null ? unavailableDataHtml(isTooltipDisplayed) : trend === 0 ? unchangedDataHtml(isTooltipDisplayed) : Math.abs(trend);
}
export function showCompetition (competition) {
  competition = Math.ceil(competition * 100);

  return competition < 35 ? i18n.t('low') : competition < 70 ? i18n.t('medium') : i18n.t('high') ;
}

export function isSharedPage(route) {
  return route.name === 'shareViewkey' ||
    route.name === 'shareViewkeyWithHash' ||
    route.name === 'shareKeywordsTable' ||
    route.name === 'shareKeywordsTableWithHash';
}

export function processedField (field, keyword, isTooltipDisplayed = true) {
  switch (field) {
    case 'source':
      return Object.values(KEYWORD_SOURCES).find(item => item.value === keyword[field]).title;
    case 'timestamp':
      // the date should always be in this format Y-m-d in the table
      return switchDateFormat(new Date(keyword.updated_at), 'Y-m-d');
    case 'grank':
      return keyword[field] === 0
        ? '<span class="empty-value">> 100</span>'
        : keyword[field] === null
          ? `<span
                class="kw kw-refresh-ccw"
                data-important-tooltip="${i18n.t('refresh-state-tooltip')}"/>`
          : keyword[field];
    case 'gpage':
      return keyword[field] === 0
        ? '<span class="empty-value">> 10</span>'
        : keyword[field] === null ?
          unavailableDataHtml(isTooltipDisplayed)
          : keyword[field];
    case 'seocomp':
      return keyword[field] === null
        ? 'n/a'
        : ['sem', 'map', 'yt'].includes(keyword.type) && keyword[field] === 0
          ? `<span class="empty-value" ${ isTooltipDisplayed && `data-important-tooltip="${i18n.t('only-for-google')}"`}>&mdash;</span>`
          : keyword[field].toLocaleString('en');
    case 'searchvolume':
      return keyword.ms === null
        ? `<span ${isTooltipDisplayed && `data-important-tooltip="${i18n.t('refreshing')}"`}>Updating</span>`
        : keyword.ms === 0
          ? unchangedDataHtml(isTooltipDisplayed)
          : keyword.ms.toLocaleString('en');
    case 'competition':
      return keyword.competition === null
        ? `<span ${isTooltipDisplayed && `data-important-tooltip="${i18n.t('refreshing')}"`}>Updating</span>`
        : keyword.competition === -1
          ? unchangedDataHtml(isTooltipDisplayed)
          : showCompetition(keyword.competition);
    case 'cpc':
      return keyword.cpc === null
        ? `<span ${isTooltipDisplayed && `data-important-tooltip="${i18n.t('refreshing')}"`}>Updating</span>`
        : keyword.cpc === 0
          ? unchangedDataHtml(isTooltipDisplayed)
          : `${keyword.currency_symbol} ${keyword.cpc.toFixed(2)}`;
    case 'visibility':
      return keyword.visibility === null
        ? `<span ${isTooltipDisplayed && `data-important-tooltip="${i18n.t('refreshing')}"`}>Updating</span>`
        : keyword.visibility === 0
          ? unchangedDataHtml(isTooltipDisplayed)
          : keyword.visibility.toLocaleString('en');
    case 'estimated_traffic':
      return keyword.estimated_traffic === null
        ? `<span ${isTooltipDisplayed && `data-important-tooltip="${i18n.t('refreshing')}"`}>Updating</span>`
        : keyword.estimated_traffic === 0
          ? unchangedDataHtml(isTooltipDisplayed)
          : keyword.estimated_traffic.toLocaleString('en');
    case 'day':
    case 'week':
    case 'month':
    case 'life':
      return showTrendOrDot(keyword.trends[field], keyword.start, isTooltipDisplayed);
    case 'best':
      return keyword[field] === 0
        ? '<span class="empty-value">> 100</span>'
        : keyword[field] === null ?
          unavailableDataHtml(isTooltipDisplayed)
          : keyword[field];
  }
}
