import apiFactory from '@/api';
import i18n from '@/i18n';
import toastr from '@/helpers/init-toastr';
import {
    SET_PDF_DATE,
    SET_PDF_VERIFIED_BY,
    SET_PDF_COMPANY_INFO,
    SET_PDF_SHOW_COLUMNS,
    SET_PDF_HISTORICAL_CHART,
} from '@/store/mutations';

const usersApi = apiFactory.get('users');

const pdfSettings = {
    state: {
        pdfShowColumns: ['g', 'day', 'week', 'month', 'ms', 'cpc', 'competition', 'comp', 'tags', 'gmb', 'source', 'visibility', 'estimated_traffic'],
        pdfHistoricalChart: true,
        pdfCompanyInfo: true,
        pdfVerifiedBy: false,
        pdfDate: false,
    },
    mutations: {
        [SET_PDF_SHOW_COLUMNS]: (state, payload) => state.pdfShowColumns = payload,
        [SET_PDF_HISTORICAL_CHART]: (state, payload) => state.pdfHistoricalChart = payload,
        [SET_PDF_COMPANY_INFO]: (state, payload) => state.pdfCompanyInfo = payload,
        [SET_PDF_VERIFIED_BY]: (state, payload) => state.pdfVerifiedBy = payload,
        [SET_PDF_DATE]: (state, payload) => state.pdfDate = payload,
    },
    getters: {
        getPdfShowColumn: state => (column) => state.pdfShowColumns.includes(column),
        getPdfShowColumns: state => state.pdfShowColumns,
        getPdfHistoricalChart: state => state.pdfHistoricalChart,
        getPdfCompanyInfo: state => state.pdfCompanyInfo,
        getPdfVerifiedBy: state => state.pdfVerifiedBy,
        getPdfDate: state => state.pdfDate,
        getPdfSettings: state => {
            return {
                show_columns: state.pdfShowColumns,
                historical_chart: state.pdfHistoricalChart,
                company_info: state.pdfCompanyInfo,
                verified_by: state.pdfVerifiedBy,
                date: state.pdfDate,
            };
        },
    },
    actions: {
        setPdfSettings({ commit }, settings) {
            const { pdf } = settings;

            if (pdf) {
                commit(SET_PDF_SHOW_COLUMNS, pdf.show_columns);
                commit(SET_PDF_HISTORICAL_CHART, pdf.historical_chart);
                commit(SET_PDF_COMPANY_INFO, pdf.company_info);
                commit(SET_PDF_VERIFIED_BY, pdf.verified_by);
                commit(SET_PDF_DATE, pdf.date);
            }
        },
        async updatePdfSettings({ dispatch, getters }, settings) {
            const oldPdfSettings = _.cloneDeep(getters.getPdfSettings);
            dispatch('setPdfSettings', { pdf: settings });

            try {
                await usersApi.updateUserCompanySettings({ pdfSettings: settings });

                toastr.s(i18n.t('saved-changes-msg'));
            } catch (e) {
                console.log(e, 'error');

                dispatch('setPdfSettings', { pdf: oldPdfSettings });
            }
        },
    },
};

export default pdfSettings;
