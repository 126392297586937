import i18n from '@/i18n';
import store from '@/store';

export const columns = {
    'checkbox': {
        colId: 'checkbox',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: false,
        resizable: false,
        pinned: 'left',
        lockPosition: 'left',
        suppressMovable: true,
        width: 32,
        minWidth: 32,
    },
    'kw': {
        headerName: i18n.t('columns.kw.title'),
        field: 'kw',
        suppressMovable: true,
        pinned: 'left',
        lockPosition: 'left',
        sort: 'asc',
        minWidth: 140,
        cellRenderer: 'KwRenderer',
    },
    'visibility': {
        headerName: i18n.t('columns.visibility.title'),
        field: 'visibility',
        sort: 'asc',
        minWidth: 105,
        cellRenderer: 'VisibilityRenderer',
    },
    'estimated_traffic': {
        headerName: i18n.t('columns.estimated_traffic.title'),
        field: 'estimated_traffic',
        sort: 'asc',
        minWidth: 140,
        cellRenderer: 'VisibilityRenderer',
    },
    'url': {
        headerName: i18n.t('columns.url.title'),
        field: 'url',
        pinned: 'right',
        lockPosition: 'right',
        minWidth: 140,
        suppressMovable: true,
        cellRenderer: 'UrlRenderer',
        cellStyle: { borderRight: 'none' },
    },
    'searchIntents': {
        headerName: i18n.t('columns.search_intents.title'),
        field: 'searchIntents',
        apiAlias: 'search_intents',
        width: 110,
        cellRenderer: 'SearchIntentsRenderer',
    },
    'start': {
        headerName: i18n.t('columns.start.title'),
        field: 'start',
        width: 70,
        editable: (params) => params.data.start !== null && !store.getters.getCurrentProject?.is_demo_project && !store.getters.getViewkeyView,
        valueParser: params => parseInt(params.newValue),
        singleClickEdit: true,
        cellRenderer: 'StartRenderer',
    },
    'gpage': {
        headerName: i18n.t('columns.gpage.title'),
        field: 'gpage',
        width: 65,
        cellRenderer: 'PageRenderer',
    },
    'best': {
        headerName: i18n.t('columns.best.title'),
        field: 'best',
        width: 70,
        cellRenderer: 'RankRenderer',
    },
    'grank': {
        headerName: i18n.t('columns.grank.title'),
        field: 'grank',
        apiAlias: 'g',
        width: 75,
        cellRenderer: 'RankRenderer',
    },
    'serpFeatures': {
        headerName: i18n.t('columns.serp_features.title'),
        field: 'serpFeatures',
        apiAlias: 'serp_features',
        width: 140,
        cellRenderer: 'SerpFeaturesRenderer',
    },
    'day': {
        headerName: i18n.t('columns.day.title'),
        field: 'day',
        width: 59,
        cellRenderer: 'PeriodRenderer',
        cellStyle: { textAlign: 'center' },
    },
    'week': {
        headerName: i18n.t('columns.week.title'),
        field: 'week',
        width: 59,
        cellRenderer: 'PeriodRenderer',
        cellStyle: { textAlign: 'center' },
    },
    'month': {
        headerName: i18n.t('columns.month.title'),
        field: 'month',
        width: 59,
        cellRenderer: 'PeriodRenderer',
        cellStyle: { textAlign: 'center' },
    },
    'life': {
        headerName: i18n.t('columns.life.title'),
        field: 'life',
        width: 59,
        cellRenderer: 'PeriodRenderer',
        cellStyle: { textAlign: 'center' },
    },
    'clicks': {
        headerName: i18n.t('columns.clicks.title'),
        field: 'clicks',
        width: 120,
        cellRenderer: 'SearchResultRenderer',
    },
    'impressions': {
        headerName: i18n.t('columns.impressions.title'),
        field: 'impressions',
        width: 120,
        cellRenderer: 'SearchResultRenderer',
    },
    'ctr': {
        headerName: i18n.t('columns.ctr.title'),
        field: 'ctr',
        width: 120,
        cellRenderer: 'SearchResultRenderer',
    },
    'searchvolume': {
        apiAlias: 'ms',
        headerName: i18n.t('columns.searchvolume.title'),
        field: 'searchvolume',
        width: 120,
        cellRenderer: 'SearchDetailRenderer',
    },
    'cpc': {
        headerName: i18n.t('columns.cpc.title'),
        field: 'cpc',
        width: 120,
        cellRenderer: 'SearchDetailRenderer',
    },
    'competition': {
        headerName: i18n.t('columns.competition.title'),
        field: 'competition',
        width: 120,
        cellRenderer: 'SearchDetailRenderer',
    },
    'seocomp': {
        headerName: i18n.t('columns.seocomp.title'),
        field: 'seocomp',
        apiAlias: 'comp',
        width: 124,
        cellRenderer: 'SearchDetailRenderer',
    },
    'tagsSplited': {
        headerName: i18n.t('columns.tagsSplited.title'),
        field: 'tagsSplited',
        width: 140,
        cellRenderer: 'TagRenderer',
        apiAlias: 'tags',
        valueFormatter: function(params) {
            return params.value.length ? params.value[0].name : '';
        }
    },
    'timestamp': {
        headerName: i18n.t('columns.timestamp.title'),
        field: 'timestamp',
        width: 105,
        cellRenderer: 'DateRenderer',
        cellStyle: { textAlign: 'left' },
    },
    'near': {
        headerName: i18n.t('columns.near.title'),
        field: 'near',
        width: 172,
        cellRenderer: 'GeoRenderer',
    },
    'gmb': {
        headerName: i18n.t('columns.gmb.title'),
        field: 'gmb',
        width: 242,
        cellRenderer: 'GeoRenderer',
    },
    'source': {
        headerName: i18n.t('columns.source.title'),
        field: 'source',
        width: 102,
        cellRenderer: 'SourceRenderer',
    },
    'keyword_last_updated': {
        headerName: i18n.t('columns.keyword_last_updated.title'),
        field: 'keyword_last_updated',
        width: 102,
        cellRenderer: 'LastUpdatedRenderer',
    },
};

export const rankColumns = [
    columns.grank.apiAlias,
    columns.gpage.field,
    columns.best.field,
    columns.start.field,
];
