import { MOBILE_WIDTH_RESOLUTION_PHONE } from '@/constants';
import { GTM_EVENTS } from '@/constants';
import { parseProject } from '../utils';
import apiFactory from '@/api';
import i18n from '@/i18n';
import router from '@/routes';
import toastr from '@/helpers/init-toastr';
import { cookieGetters } from '@/helpers/cookieManager';
import { scrollToSideBarItem } from '@/helpers/scroll-to-side-bar-item';
import { Events, EventBus } from '@/events';

const projectsApi = apiFactory.get('projects');

import {
    SET_ACTIVE_GROUPS_LOADED,
    SET_ACTIVE_GROUPS,
    SET_CHECKED_GROUPS,
    SET_EDITABLE_GROUP,
    SET_IS_GROUP_ADDING,
    SET_KEYWORDS,
    SET_OPENED_GROUPS_KEYS,
    SET_OVERVIEW_CHARTS_DATA,
    SET_OVERVIEW_DATA,
    SET_SUGGESTED_REGIONS,
    SET_SUGGESTED_URLS,
    SET_CURRENT_GROUP_OBJECT,
    SET_ALL_PROJECTS_LAST_UPDATE
} from '../mutations';
import { getLatestTimestamp } from '../../helpers/lastAddedKeywordsChecking';


const manageProjects = {
    state: {
        activeProjects: [],
        checkedProjects: [],
        editableProject: null,
        isActiveProjectsLoaded: false,
        isProjectAdding: false,
        openedProjectsKeys: [],
        suggestedRegions: [],
        suggestedUrls: [],
    },
    mutations: {
        [SET_ACTIVE_GROUPS_LOADED]: (state, loaded) => state.isActiveProjectsLoaded = loaded,
        [SET_ACTIVE_GROUPS]: (state, projects) => state.activeProjects = Object.freeze(projects),
        [SET_CHECKED_GROUPS]: (state, checked) => state.checkedProjects = checked,
        [SET_EDITABLE_GROUP]: (state, project) => state.editableProject = project,
        [SET_IS_GROUP_ADDING]: (state, payload) => state.isProjectAdding = payload,
        [SET_OPENED_GROUPS_KEYS]: (state, keys) => state.openedProjectsKeys = keys,
        [SET_SUGGESTED_REGIONS]: (state, regions) => state.suggestedRegions = regions,
        [SET_SUGGESTED_URLS]: (state, urls) => state.suggestedUrls = urls,
    },
    getters: {
        getOriginalActiveProjects: state => {
            const projects = _.cloneDeep(state.activeProjects);
            return projects.sort((a, b) => a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1);
        },
        getActiveProjects: (state, getters) => {
            const originalActiveProjects = _.cloneDeep(getters.getOriginalActiveProjects);
            const projects = [];
            // This code sorts and pushes fetched projects into array
            // Child projects are pushed into their parents
            originalActiveProjects
                // Subprojects after projects
                .sort(project => project.attributes.name.includes('[sub]') ? 1 : -1)
                .forEach(project => {
                    // Split name to array: 1st el - project, 2nd - subproject (or undefined)
                    const splittedProjectName = project.attributes.name.split('[sub]');
                    // If this is a subproject
                    if (splittedProjectName.length > 1) {
                        // Find the parent of this subproject
                        const parentProject = projects.find(parent => parent.name === splittedProjectName[0]);
                        // Push subproject into its parent
                        if (parentProject) {
                            if (typeof parentProject.children === 'undefined') {
                                parentProject.children = [];
                            }
                            parentProject.children.push(parseProject(project, parentProject.project_id));
                        } else {
                            // deleted parent
                            project.attributes.name = project.attributes.name.replace(`${splittedProjectName[0]}[sub]`, '');

                            projects.push({
                                children: [{
                                    ...project.attributes,
                                    id: project.id,
                                    isSubproject: true,
                                    parent: splittedProjectName[0],
                                    type: project.type,
                                    unixTimestamp: new Date(project.attributes.updated_at).getTime(),
                                }],
                                deleted: true,
                                id: `${splittedProjectName[0]}-deleted`,
                                isSubproject: false,
                                keywords_count: { ACTIVE: 0 },
                                name: splittedProjectName[0],
                                parent: '',
                                type: project.type,
                                unixTimestamp: '',
                                updated_at: '',
                            });
                        }
                        // If this is a project
                    } else {
                        projects.push(parseProject(project));
                    }
                });

            return projects;
        },
        getFilteredAndSortedProjects: (state, getters) => {
            let activeProjects = _.cloneDeep(getters.getActiveProjects);
            const sortType = getters.getDisplaySetting('category_sort');
            const sorter = getters.getProjectsSorter;
            const search = getters.getUserSetting('projectsSearch');
            const filterByTags = getters.getUserSetting('filterByTags');
            function sortByOption(array) {
                return array.sort((a, b) => {
                    // we sort by project name, timestamp or kwcount
                    switch (sorter) {
                        case 'timestamp':
                            return sortType === 'asc'
                                ? a.unixTimestamp > b.unixTimestamp ? 1 : -1
                                : a.unixTimestamp < b.unixTimestamp ? 1 : -1;
                        case 'kwcount':
                            return sortType === 'asc'
                                ? a.keywords_count.ACTIVE > b.keywords_count.ACTIVE ? 1 : -1
                                : a.keywords_count.ACTIVE < b.keywords_count.ACTIVE ? 1 : -1;
                        case 'tags':
                            return sortType === 'asc'
                                ? a.tags.length < b.tags.length ? 1 : -1
                                : a.tags.length > b.tags.length ? 1 : -1;
                        case 'category':
                            return sortType === 'desc'
                                ? a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1
                                : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                    }
                });
            }

            activeProjects = sortByOption(activeProjects);

            for (let i = 0; i < activeProjects.length; i++) {
                if (activeProjects[i].children) {
                    activeProjects[i].children = sortByOption(activeProjects[i].children)
                }
            }

            let filteredProjects = [];

            if (search) {
                filteredProjects = activeProjects.filter(project => {
                    const isVisible = project.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;

                    if (project.children) {
                        project.children = project.children.filter(child => {
                            // filter children that matches the search criteria
                            const isChildVisible = child.name.toLowerCase().indexOf(search.toLowerCase()) !== -1

                            // return the children, either if it matches the search of its parent does
                            return isVisible || isChildVisible;
                        });
                    }

                    return (project.children && (isVisible === false && project.children.length !== 0)) ? true : isVisible;
                });

                return filteredProjects;
            }

            if (filterByTags) {
                filteredProjects = activeProjects.filter(project => project.tags.includes(filterByTags)
                );
                return filteredProjects;
            }

            return activeProjects;
        },
        getSuggestedRegions: state => tabs => state.suggestedRegions.filter(region => tabs.includes(region.type)),
        getSuggestedUrls: state => tabs => state.suggestedUrls.reduce((acc, cur) => {
            if (tabs.includes(cur.type) && !acc.includes(cur.url)) {
                acc.push(cur.url);
            }

            return acc;
        }, []),
        getEditableProject: state => state.editableProject,
        getParsedEditableProject: state => parseProject(state.editableProject),
        getActiveProjectsLoaded: state => state.isActiveProjectsLoaded,
        getCheckedProjects: state => state.checkedProjects,
        getIsProjectAdding: state => state.isProjectAdding,
        getOpenedProjectsKeys: state => state.openedProjectsKeys,
        getOriginalCheckedProjects: (state, getters) => {
            return getters.getOriginalActiveProjects.reduce((acc, cur) => {
                if (getters.getCheckedProjects.includes(cur.id)) {
                    acc.push(parseProject(cur));
                }

                return acc;
            }, []);
        },
        /**
         * Getting exact property of current project (or subproject if selected)
         * prop - the key of the property to get from the project
         * returns categoryProperty or null
         */
        getProjectProperty: (state, getters) => prop => getters.getCurrentProject ? getters.getCurrentProject[prop] : null,
        /**
         * Getter that returns full project name like this: parent[sub]child by given project object
         */
        getFullProjectName: () => project => (project.parent ? `${project.parent}[sub]` : '') + project.name,
        /**
         * Getter that gets id of the project.
         * Returns project object or null.
         */
        getProjectByProjectId: (state, getters) => project_id => {
            const project = getters.getOriginalActiveProjects.find(project => project.attributes.project_id === project_id);
            return project ? parseProject(project) : project;
        },
        /**
         * Getter that gets name of the project.
         * Returns project object or null.
         */
        getProjectById: (state, getters) => id => {
            const project = getters.getOriginalActiveProjects.find(project => project.id === id);
            return project ? parseProject(project) : project;
        },
        /**
         * Getter that gets name and parent name of the project.
         * Returns project object and its localization at projects tree (indexes) or null.
         */
        getProjectByName: (state, getters) => ({ name, parent }) => {
            const activeProjects = getters.getActiveProjects;
            let necessaryProject = null;

            if (parent) {
                for (let i = 0; i < activeProjects.length; i++) {
                    const activeProject = activeProjects[i];
                 
                    if (
                        activeProject.name === parent
                        && activeProject.children
                        && activeProject.children.length
                    ) {
                        for (let j = 0; j < activeProject.children.length; j++) {
                            const activeChild = activeProject.children[j];

                            if (activeChild && activeChild.name.toLowerCase() === name.toLowerCase()) {
                                necessaryProject = {
                                    project: activeChild,
                                    projectIndex: i,
                                    subProjectIndex: j,
                                };

                                break;
                            }
                        }
                    }
                }
            } else {
                for (let i = 0; i < activeProjects.length; i++) {
                    const activeProject = activeProjects[i];
                    if (name && activeProject.name.toLowerCase() === name.toLowerCase()) {
                        necessaryProject = {
                            project: activeProject,
                            projectIndex: i,
                        };

                        break;
                    }
                }
            }

            return necessaryProject;
        },
        getProjectAndSubprojectsKeywordsCount: (state, getters) => (project) => {
            let keywordsNumber = project.keywords_count.ACTIVE;
            const activeProjects = getters.getActiveProjects;

            if (!project) {
                return;
            }

            // find the original project in the active projects, they are sorted and have more information
            const activeProject = activeProjects.find(el => el.name === project.name);

            if (activeProject && activeProject.children && activeProject.children.length > 0) {
                // sum up the children keywords count to the keywords number
                activeProject.children.reduce(
                    (acc,child) => {
                        return acc + child.keywords_count.ACTIVE;
                    }, keywordsNumber
                );
            }

            return keywordsNumber;
        },
        getSubProjectsByParentId: (state, getters) => (id) => {
            const projects = getters.getActiveProjects;
            const currenProject = projects.find(item => item.project_id === id);

            if (currenProject && currenProject.children) {
                return currenProject.children;
            }
            return [];
        },
        getCategoriesForSelectedKeywords(state, getters) {
            const {
                getCurrentProject,
                getActiveProjects,
                getOtherSetting,
                getIsAllCheckboxesSelected,
                getSelectedKeywordsWithAllData
            } = getters;

            let currentProject = getCurrentProject;
            if (currentProject && !currentProject.isSubproject) {
                currentProject = getActiveProjects.find(project => project.id === getCurrentProject.id);
            }
            const isDisplayingSubcategories = getOtherSetting('subcat') === 'show';
            if (getCurrentProject && (!isDisplayingSubcategories || getCurrentProject.isSubproject || !currentProject.children)) {
                return [getCurrentProject.id];
            }
            else {
                if (getIsAllCheckboxesSelected) {
                    const idsOfSelectedProjectAndSubprojects = [currentProject.id];
                    currentProject.children.forEach(project => idsOfSelectedProjectAndSubprojects.push(project.id));
                    return idsOfSelectedProjectAndSubprojects;
                }
                else {
                    const categories = {};
                    getSelectedKeywordsWithAllData.forEach(el => categories[el.category] = true);
                    return Object.keys(categories);
                }
            }
        }
    },
    actions: {
        async fetchActiveProjects({ commit }) {
            commit(SET_ACTIVE_GROUPS_LOADED, false);

            try {
                const res = await projectsApi.fetchActive();
                const latestTimestamp = getLatestTimestamp(res.data.data);
                commit(SET_ALL_PROJECTS_LAST_UPDATE, latestTimestamp);
                
                commit(SET_ACTIVE_GROUPS, res.data.data);
            } catch (error) {
                //
            } finally {
                commit(SET_ACTIVE_GROUPS_LOADED, true);
            }
        },
        checkProject({ getters, dispatch }, projectId) {
            const { getCheckedProjects } = getters;
            const selected = getCheckedProjects.filter(el => el !== projectId);

            if (selected.length === getCheckedProjects.length) {
                selected.push(projectId);
            }

            dispatch('setCheckedGroups', selected);
        },
        checkProjectWithChildren({ getters, dispatch }, projectId) {
            const selected = getters.getOriginalActiveProjects.reduce((acc, cur) => {
                if (cur.id === projectId || cur.id.indexOf(`${projectId}[sub]`) === 0) {
                    acc.push(cur.id);
                }

                return acc;
            }, []);

            dispatch('setCheckedGroups', selected);
        },
        checkOnlyProject({ dispatch }, projectId) {
            const selected = [projectId];
            dispatch('setCheckedGroups', selected);
        },
        checkAllProjects({ getters, dispatch }) {
            const checkedProjects = getters.getSortedAndFilteredProjectsWithMetrics.map(project => project.id);
            dispatch('setCheckedGroups', checkedProjects);
        },
        resetCheckedProjects({ commit }) {
            commit(SET_CHECKED_GROUPS, []);
        },
        setOpenedProjectsKeys: ({ getters, commit }, { project = {} }) => {
            const openedProjects = getters.getOpenedProjectsKeys;
            const isChild = project.parent ? true : false;
            if (openedProjects.includes(project.id)) {
                commit(SET_OPENED_GROUPS_KEYS, openedProjects.filter(el => el !== project.id));
            } else {
                if (isChild) {
                    const filteredData = openedProjects.filter(el => {
                        return el === project.parent || el === `${project.parent}-deleted`;
                    });
                    commit(SET_OPENED_GROUPS_KEYS, [...filteredData, project.id]);
                } else {
                    let arrData = [project.id];
                    if (project.children && project.children.length) {
                        const filteredData = openedProjects.filter(el => {
                            const foundedItem = project.children.find((item) => item.id === el);
                            return !!foundedItem;
                        });

                        arrData = [...filteredData, project.id];
                    }
                    commit(SET_OPENED_GROUPS_KEYS, arrData);
                }
            }
        },
        setAllOpenedProjectsKeys({ commit }, ids) {
            commit(SET_OPENED_GROUPS_KEYS, ids);
        },
        async addEmptyProject({ dispatch }, project) {
            await dispatch('addProject', project);
        },
        async addProject({ getters, commit, dispatch }, params) {
            const { getUserData } = getters;
            const activeProjects = _.cloneDeep(getters.getOriginalActiveProjects);
            commit(SET_IS_GROUP_ADDING, true);

            const {
                currencyCode,
                categoryName,
                selectedParentCategory,
                validDomain
            } = params;

            const postData = {
                data: {
                    type: 'project',
                    attributes: {
                        category: getters.getFullProjectName({ name: categoryName, parent: selectedParentCategory }),
                        currency_code: currencyCode,
                    },
                    ...(validDomain ? {valid_domain: true} : {})
                }
            };

            try {
                const res = await projectsApi.add(postData);

                if (selectedParentCategory) {
                    EventBus.emit(Events.CREATE_PROJECT, {name: categoryName, currency_code: currencyCode});
                } else {
                    EventBus.emit(Events.CREATE_GROUP, {name: categoryName, currency_code: currencyCode});
                }

                const project = res.data.data;
                commit(SET_ACTIVE_GROUPS, [...activeProjects, project]);
                dispatch('setKeywords');

                // In the case of the simple addition of the project (not edition of keyword(s))
                // user should add at least one keyword, because project can't be empty
                if (getters.getTutorialMode === false) {
                    /**
                     * GTM custom event d_add_project
                     */
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: GTM_EVENTS.PROJECT_ADDED,
                        uid: getUserData.userId,
                    });
                    dispatch('resetMetricsData');
                    router.push({ name: 'addKeywords', params: { id: project.id } });
                }
                dispatch('setCurrentProject');
                dispatch('setAddProjectScreenOpened', false);
                if (window.screen.width <= MOBILE_WIDTH_RESOLUTION_PHONE) {
                    dispatch('setSideBarOpened', false);
                } else {
                    let foundedItem = null;
                    getters.getActiveProjects.forEach((item) => {
                        const newProjectId = project.attributes.project_id;
                        if (item.project_id === newProjectId) {
                            foundedItem = item;
                        } else {
                            if (item.children && item.children.length) {
                                const foundedChildren = item.children.find((item1) => item1.project_id === newProjectId);
                                if (foundedChildren) {
                                    foundedItem = foundedChildren;
                                }
                            }
                        }
                    })
                    if (foundedItem) {
                        if (foundedItem.parent) {
                            const isOpened = getters.getOpenedProjectsKeys.find((item) => item === foundedItem.parent)
                            if (!isOpened) {
                                const foundedParent = getters.getActiveProjects.find((item) => item.project_id === foundedItem.parent_project_id);
                                dispatch('setOpenedProjectsKeys', { project: foundedParent });
                            }
                        }
                        Vue.nextTick(() => {
                            scrollToSideBarItem(foundedItem.project_id);
                        });
                    }
                }
            } catch (error) {
                console.log(error, 'error')
            } finally {
                commit(SET_IS_GROUP_ADDING, false);
            }
        },
        async deleteProjects({ dispatch }, { manageList, removedFromProjectDashboard, isSubproject = false }) {
            try {
                await Promise.all(manageList.map(id => projectsApi.delete(id)));
                dispatch('removeActiveProjectsFromStore', { manageList, removedFromProjectDashboard });

                const successMessage = manageList.length > 1
                    ? i18n.t('succ-project-msg-plural', { action: 'deleted' })
                    : i18n.t('succ-project-msg-single', { action: 'deleted', type: isSubproject ? i18n.t('group') : i18n.t('project-upper') });

                toastr.s(successMessage);
            } catch (error) {
                //
            }
        },
        async archiveProjects({ dispatch }, { manageList, removedFromProjectDashboard, isSubproject = false }) {
            try {
                await Promise.all(manageList.map(id => projectsApi.archive(id)));
                dispatch('removeActiveProjectsFromStore', { manageList, removedFromProjectDashboard });
                const successMessage = manageList.length > 1
                    ? i18n.t('succ-project-msg-plural', { action: 'archived' })
                    : i18n.t('succ-project-msg-single', { action: 'archived', type: isSubproject ? i18n.t('group') : i18n.t('project-upper') });

                toastr.s(successMessage);
            } catch (error) {
                //
            }
        },
        removeActiveProjectsFromStore({ getters, commit, dispatch }, { manageList, removedFromProjectDashboard }) {
            const { name, params } = router.history.current;

            const {
                getCurrentProject,
                getOriginalActiveProjects,
                getOverviewChartsData,
                getOverviewView,
                getProjectByName,
            } = getters;

            const newProjects = getOriginalActiveProjects.filter(project => !manageList.includes(project.id));
            dispatch('fetchUsed');
            dispatch('resetCheckedProjects');
            dispatch('setTutorialMode');
            commit(SET_ACTIVE_GROUPS, newProjects);

            if (name === 'Overview') {
                if (getOverviewView === 'chart' && getOverviewChartsData.length) {
                    dispatch('fetchOverviewChartsData');
                }

                if (getOverviewView === 'table') {
                    const projectKey = getters.getOverviewPaginateWithAPI ? 'id' : 'project_name';
                    commit(SET_OVERVIEW_DATA, {
                        data: getters.getProjectsWithMetricsPaginatedWithAPI.filter(p => !manageList.includes(p[projectKey])),
                        showall: cookieGetters.getShowAll(),
                    });
                }
            }

            if (!getCurrentProject) {
                return;
            }

            if (!manageList.includes(getCurrentProject.id) || name === 'Overview') {
                return;
            }

            // if project was archived/deleted from project dashboard page push to overview
            if (removedFromProjectDashboard) {
                router.push({ name: 'Overview' });
                return;
            }

            /**
             * If deleted current project, then we redirect to the first available project from the list if any.
             * Else if deleted subproject, then we redirect to the its parent
             */
            const firstProjectId = getOriginalActiveProjects[0] ? getOriginalActiveProjects[0].id : null;

            if (params.id.includes('[sub]')) {
                const parent = getProjectByName({ name: params.id.split('[sub]')[0], parent: '' });

                router.push({
                    name: 'keywordList', params: {
                        id: parent && !parent.project.deleted
                            ? parent.project.id
                            : firstProjectId
                    }
                });
                if (parent && !parent.project.deleted) {
                    router.push({ name: 'keywordList', params: { id: parent.project.id } });
                }
            } else {
                router.push({ name: 'keywordList', params: { id: firstProjectId } });
            }
        },
        // @TODO this method should be refactored after new api will be fixed
        async updateProject({ getters, commit, dispatch }, updatedProject) {
            const activeProjects = _.cloneDeep(getters.getOriginalActiveProjects);
            const oldProject = getters.getParsedEditableProject;
            const updatedProjectForStore = _.cloneDeep(getters.getEditableProject);
            const newName = getters.getFullProjectName(updatedProject);
            const oldName = getters.getFullProjectName(oldProject);
            const { current } = router.history;

            let changedProjects = [updatedProjectForStore];
            // for save checkboxes state
            const ids = {};

            if (oldName !== newName) {
                const promises = [];
                const queryParams = {
                    params: {
                        action: 'renamecategory',
                        auth: cookieGetters.getApiKey(),
                        newcatname: newName,
                        oldcatname: oldName,
                        id: updatedProject.project_id,
                    },
                };

                promises.push(axios.get('', queryParams));

                try {
                    const res = await Promise.all(promises);

                    res.forEach(({ data }) => {
                        if (data.RESPONSE !== 'SUCCESS') {
                            data.MESSAGE && toastr.e(data.MESSAGE);
                        } else if (current.name !== 'Overview') {
                            router.push({ name: 'keywordList', params: { id: encodeURIComponent(updatedProjectForStore.id) } });
                        }

                        if (data.RESPONSE === 'SUCCESS') {
                            updatedProjectForStore.attributes.name = newName;
                            updatedProjectForStore.id = newName;
                            changedProjects = [updatedProjectForStore];
                            // for save checkboxes state
                            ids[getters.getEditableProject.id] = newName;

                            // this part because of childrens should be renamed too (before [sub] part)
                            if (!oldName.includes('[sub]')) {
                                const oldChildren = activeProjects.filter(project => project.attributes.name.includes(`${oldName}[sub]`));
                                oldChildren.forEach(project => {
                                    const childForRenaming = _.cloneDeep(project);
                                    const oldChildName = project.attributes.name;
                                    const splittedOldName = oldChildName.split('[sub]');
                                    promises.push(axios.get('', {
                                        params: {
                                            action: 'renamecategory',
                                            auth: cookieGetters.getApiKey(),
                                            newcatname: oldChildName.replace(splittedOldName[0], newName),
                                            oldcatname: oldChildName,
                                            id: project.attributes.project_id,
                                        },
                                    }));

                                    childForRenaming.attributes.name = oldChildName.replace(splittedOldName[0], newName);
                                    childForRenaming.id = oldChildName.replace(splittedOldName[0], newName);
                                    changedProjects.push(childForRenaming);
                                    // for save checkboxes state
                                    ids[project.id] = childForRenaming.id;
                                });
                            }
                        }
                    });
                } catch (error) {
                    console.warn(error);
                }
            }

            commit(
                SET_ACTIVE_GROUPS,
                [...activeProjects.filter(project => !project.attributes.name.includes(oldName)), ...changedProjects],
            );

            // updates project name if route is Overview
            if (oldName !== newName && current.name === 'Overview') {
                if (getters.getOverviewView === 'table') {
                    const projectsWithMetrics = _.cloneDeep(getters.getProjectsWithMetrics);
                    projectsWithMetrics.find(el => {
                        if (el.id === oldName) {
                            el.id = newName;
                            return true;
                        } else {
                            return false;
                        }
                    });

                    commit(SET_OVERVIEW_DATA, { data: projectsWithMetrics, showall: cookieGetters.getShowAll() });
                }

                if (getters.getOverviewView === 'chart') {
                    const projectsWithMetricsAndCharts = _.cloneDeep(getters.getOverviewChartsData);
                    projectsWithMetricsAndCharts.find(el => {
                        if (el.id === oldName) {
                            el.id = newName;
                            return true;
                        } else {
                            return false;
                        }
                    });

                    commit(SET_OVERVIEW_CHARTS_DATA, projectsWithMetricsAndCharts);
                }
            }

            // for save checkboxes state
            const newCheckedCategory = [];
            getters.getCheckedProjects.forEach(id => newCheckedCategory.push(ids.hasOwnProperty(id) ? ids[id] : id));
            dispatch('setCheckedGroups', newCheckedCategory);
        },
        async fetchSuggestedRegions({ getters, commit }) {
            if (!getters.getCurrentProject) return;
            const { id } = getters.getCurrentProject;

            try {
                const res = await projectsApi.fetchSuggestedRegions(id);
                commit(SET_SUGGESTED_REGIONS, res.data.data);
            } catch (e) {
                throw (e);
            }
        },
        async fetchSuggestedUrls({ getters, commit }) {
            if (!getters.getCurrentProject) return;
            const { id } = getters.getCurrentProject;

            try {
                const res = await projectsApi.fetchSuggestedUrls(id);
                commit(SET_SUGGESTED_URLS, res.data.data);
            } catch (e) {
                throw (e);
            }
        },

        async updateProjectSettings({ getters, commit }, { projectId, payload, dontUpdate }) {
            try {
                const res = await projectsApi.update(projectId, payload);
                if(!dontUpdate){
                    EventBus.emit(Events.UPDATE_PROJECT_SPECIFIC_BRANDING, {projectId, payload});
                }
                const projects = getters.getOriginalActiveProjects;
                const updatedProjects = projects.map(item => {
                    if (item.id === projectId && res.data.data) {
                        return res.data.data;
                    } else if (item.id.indexOf(projectId + '[sub]') !== -1 && res.data?.data) {
                        item.id = item.id.replace(projectId, res.data.data.id);
                        item.attributes.name = item.attributes.name.replace(projectId, res.data.data.id);
                    }
                    return item;
                });

                commit(SET_ACTIVE_GROUPS, updatedProjects);
                if(!dontUpdate){
                    commit(SET_CURRENT_GROUP_OBJECT, parseProject(res.data.data));
                }
            } catch (e) {
                throw (e);
            }
        },
        setCheckedGroups({ commit }, payload = []) {
            commit(SET_CHECKED_GROUPS, payload);
        },
        setKeywords({commit}, keywords = []) {
            commit(SET_KEYWORDS, keywords);
        },
    }
};

export default manageProjects;
